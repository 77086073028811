import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Page from '../organisms/Page';

import Schedule from '../organisms/Schedule';

const windowGlobal = typeof window !== 'undefined' && window;

const SchedulePage = ({ ...pageData }) => {
  const {
    strapiSchedulePage: { page_meta },
  } = useStaticQuery(
    graphql`
      query schedulePageQuery {
        strapiSchedulePage {
          page_meta {
            page_title_fr
            page_title_en
          }
        }
      }
    `,
  );

  useEffect(() => {
    if (windowGlobal) {
      const { hash } = window.location;
      const elmnt = document.getElementById(hash?.substring(1));
      if (elmnt?.scrollIntoView) {
        elmnt.scrollIntoView();
      }
    }
  }, []);

  return (
    <Page title={{ en: page_meta.page_title_en, fr: page_meta.page_title_fr }} data={pageData}>
      <Schedule />
    </Page>
  );
};

export default SchedulePage;
