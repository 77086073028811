/* eslint-disable react/jsx-curly-newline */
import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { format, parse } from 'date-fns';

import { useLangStore } from '../../../store';
import Show from '../../molecules/Show';
import { H3, P } from '../../atoms/Text';
import ContentWrapper from '../../Layout/ContentsWrapper';

import { useTranslation } from '../../../hooks/TranslationHooks';

import { getScheduleFromData, formatDay } from './helpers';
import { DateHeader, DayList, NoResultWrapper, Fade } from './styles';
import { slugify } from '../../../helpers/string';

const Schedule = () => {
  const currentLang = useLangStore(store => store.currentLang);
  const [openItemId, setOpenItemId] = useState('');
  const noResultText = useTranslation({ key: 'noResultUpcoming' })[currentLang];

  const data = useStaticQuery(graphql`
    query {
      allStrapiUpcomingShow(sort: { fields: [broadcast_start_time], order: ASC }) {
        nodes {
          fields {
            slug
          }
          id
          title
          broadcast_start_time
          broadcast_end_time
          description_en
          description_fr
          active
          image {
            publicURL
          }
          hosts {
            Name
            image {
              publicURL
            }
          }
          tags {
            name_en
            name_fr
          }
          languages {
            name
          }
        }
      }
    }
  `);
  const schedule = getScheduleFromData(data);

  return (
    <ContentWrapper
      css={`
        position: relative;
        padding-bottom: 64px;
      `}
    >
      {Object.keys(schedule).length !== 0 ? (
        <>
          {Object.keys(schedule || {})
            .sort((a, b) => new Date(a) - new Date(b))
            .map((day, dayIndex) => {
              return (
                <DayList key={day} isLast={dayIndex + 1 === Object.keys(schedule || {}).length}>
                  <DateHeader>
                    <H3>{formatDay(day, currentLang)}</H3>
                  </DateHeader>
                  {schedule[day].map(
                    (
                      {
                        title,
                        hosts,
                        broadcast_start_time: broadcastStartTime,
                        broadcast_end_time: broadcastEndTime,
                        description_en: descriptionEn,
                        description_fr: descriptionFr,
                        image,
                        id,
                        tags,
                        languages,
                        fields,
                      },
                      i,
                    ) => {
                      const startDay = format(new Date(broadcastStartTime), 'yyyy/M/d');
                      const datestring = `${startDay} ${broadcastEndTime?.substring(0, 5)}`;
                      const broadcastEnd = new Date(datestring);
                      const idSlug = `${slugify(title)}-${format(
                        new Date(broadcastStartTime),
                        'dd-MM-yyyy',
                      )}`;
                      const handleItemClick = () => {
                        if (openItemId === id) {
                          setOpenItemId(0);
                        } else {
                          setOpenItemId(id);
                        }
                      };
                      return (
                        <Show
                          key={id}
                          name={title}
                          hosts={hosts}
                          startTime={new Date(broadcastStartTime)}
                          endTime={broadcastEnd}
                          image={image?.publicURL}
                          isLast={i + 1 === schedule[day].length}
                          onClick={handleItemClick}
                          isOpen={id === openItemId}
                          description={{
                            en: descriptionEn,
                            fr: descriptionFr,
                          }}
                          id={idSlug}
                          tags={tags}
                          languages={languages}
                          slug={fields.slug}
                        />
                      );
                    },
                  )}
                </DayList>
              );
            })}
          <Fade />
        </>
      ) : (
        <NoResultWrapper>
          <P align="center">{noResultText}</P>
        </NoResultWrapper>
      )}
    </ContentWrapper>
  );
};

Schedule.propTypes = {};

export default Schedule;
